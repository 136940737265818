import React from 'react'
import { Navigate } from "react-router"
import { Routes, Route } from "react-router-dom"
import RequireAuth from "./helpers/require-auth";
import './App.css';

import Home from './Pages/Home'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<RequireAuth child={Home} auth={false} />} />
        
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

    </div>
  );
}

export default App;
